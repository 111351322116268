"use client";

import { useState } from "react";
import { default as ImageNext } from "next/image";
import type { ImageProps } from "next/image";
import { pathImageNotAvailable } from "@/common";
type Props = ImageProps;
export default function Image({
  alt,
  ...props
}: Props) {
  const [src, setSrc] = useState(props.src);
  return <ImageNext {...props} width={500} height={500} alt={alt} src={src} blurDataURL="/images/jpg/loading-7528_256.gif" placeholder="blur" onError={() => setSrc(pathImageNotAvailable)} />;
}