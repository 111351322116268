"use client";

import { Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
import { NavigateRouter, classNames } from "@/utils";
import { User } from "@/types";
import HeaderPC from "./HeaderPC";
import HeaderSP from "./HeaderSP";
import { logout } from "@/app/lib/data";
import Image from "next/image";
type Props = {
  user: User | null;
};
export default function Header({
  user
}: Props) {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const pathname = usePathname();
  const userNavigation = [{
    name: t("header.Post"),
    onClick: () => router.push(NavigateRouter.SELLERNET)
  },
  // { name: t('common.Profile'), onClick: () => navigate(NavigateRouter.PROFILE) },
  {
    name: t("common.Sign out"),
    onClick: () => logout()
  }];
  const navigation = [{
    name: t("header.Buy"),
    href: NavigateRouter.REAL_ESTATE_FOR_SALE,
    current: false
  }, {
    name: t("header.Sell"),
    href: NavigateRouter.REAL_ESTATE_FOR_RENT,
    current: false
  }, {
    name: t("header.News"),
    href: NavigateRouter.NEWS,
    current: false
  }];
  return <>
      <Popover as="nav" className="fixed z-50 w-full border-b border-gray-200 bg-violet-bds">
        {({
        open
      }) => <>
            <div className="mx-auto max-w-[99%] px-4 lg:px-6">
              <div className="flex h-16 justify-between">
                <div className="-mr-2 flex items-center lg:hidden">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                    <span className="sr-only">Open main menu</span>
                    {open ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
                  </Popover.Button>
                </div>
                <div className="flex justify-center">
                  <Link href={NavigateRouter.HOME} className="flex flex-shrink-0 items-center">
                    <Image src="/images/logo/Logo-BDS-369-t-wh.svg" alt="logo-bds369" width={50} height={50} className="block h-12 w-auto lg:h-16" />
                  </Link>
                  <div className="hidden sm:-my-px sm:ml-6 sm:space-x-8 lg:flex">
                    {navigation.map(item => <Link key={item.name} href={item.href} className={classNames(pathname.includes(item.href) ? "border-yellow-bds text-yellow-bds" : "border-transparent text-white hover:border-yellow-bds", "inline-flex items-center border-b-2 px-1 pt-1 text-lg font-bold hover:text-violet-300")} aria-current={item.current ? "page" : undefined}>
                        {item.name}
                      </Link>)}
                  </div>
                </div>
                <HeaderPC user={user} userNavigation={userNavigation} />
              </div>
            </div>

            <HeaderSP user={user} userNavigation={userNavigation} navigation={navigation} />
          </>}
      </Popover>
    </>;
}