"use client";

import type { FC } from "react";
import { Fragment, memo } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BellIcon, ChevronDownIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import dynamic from "next/dynamic";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
import Avatar from "../atoms/Avatar";
import type { User } from "@/types";
import { classNames, NavigateRouter } from "@/utils";
const ButtonHeader = dynamic(() => import("@/components/atoms/ButtonHeader"));
const ButtonOutline = dynamic(() => import("@/components/atoms/ButtonOutline"));
type Props = {
  user: User | null;
  userNavigation: {
    name: string;
    onClick: () => void;
  }[];
};
const HeaderPC: FC<Props> = memo(function HeaderPC({
  user,
  userNavigation
}) {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const pathname = usePathname();
  return <>
      <div className="hidden lg:ml-6 lg:flex lg:items-center">
        <div className="flex flex-1 items-center justify-end gap-x-4">
          {/* <ButtonHeader>
                      <HeartIcon className="h-6 w-6" aria-hidden="true" />
                    </ButtonHeader> */}
          {user ? <>
              <ButtonHeader>
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-5 w-5" aria-hidden="true" />
              </ButtonHeader>
              <Menu as="div" className="relative">
                <div className="group">
                  <Menu.Button className="flex max-w-xs items-center rounded-md border border-transparent border-violet-500 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none group-hover:text-violet-300">
                    <span className="sr-only">Open user menu</span>
                    <Avatar className="h-5 w-5 rounded-full object-cover" labelText="Open user menu" src={undefined} alt={`${user.username}`} />
                    <span className="ml-4 text-sm text-yellow-bds group-hover:text-violet-300">
                      {user.last_name} {user.first_name}
                    </span>
                    <ChevronDownIcon className="ml-1 h-4 w-4" />
                  </Menu.Button>
                </div>
                <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map(item => <Menu.Item key={item.name}>
                        {({
                    active
                  }) => <button onClick={item.onClick} className={classNames(active ? "bg-gray-100" : "", "block w-full px-4 py-2 text-left text-sm text-gray-700")}>
                            {item.name}
                          </button>}
                      </Menu.Item>)}
                  </Menu.Items>
                </Transition>
              </Menu>
            </> : <>
              <ButtonHeader onClick={() => router.push(NavigateRouter.LOGIN)}>
                {t("header.Sign in")}
              </ButtonHeader>
              <ButtonHeader onClick={() => router.push(NavigateRouter.REGISTER)}>
                {t("header.Sign up")}
              </ButtonHeader>
            </>}
          <ButtonOutline className="text-violet-bds" onClick={() => user ? router.push(NavigateRouter.SELLERNET) : router.push(NavigateRouter.LOGIN)}>
            {t("header.Post")}
          </ButtonOutline>
        </div>
      </div>
      {user ? pathname.includes(NavigateRouter.SELLERNET) ? <div className="sm:hidden"></div> : <div className="flex items-center lg:hidden">
            <Link href={NavigateRouter.SELLERNET}>
              <UserCircleIcon className="h-7 w-7 text-white" />
            </Link>
          </div> : <div className="flex items-center lg:hidden">
          <Link href={NavigateRouter.LOGIN}>
            <UserCircleIcon className="h-7 w-7 text-white" />
          </Link>
        </div>}
    </>;
});
export default HeaderPC;