"use client";

import { memo } from "react";
import { Popover } from "@headlessui/react";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
import Avatar from "../atoms/Avatar";
import type { User } from "@/types";
import { classNames, NavigateRouter } from "@/utils";
type Props = {
  user: User | null;
  userNavigation: {
    name: string;
    onClick: () => void;
  }[];
  navigation: {
    name: string;
    href: string;
    current: boolean;
  }[];
};
const HeaderSP = memo(function HeaderSP({
  user,
  userNavigation,
  navigation
}: Props) {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const pathname = usePathname();
  return <Popover.Panel className="lg:hidden">
      <div className="space-y-1 pb-3 pt-2">
        {navigation.map((item, index) => <Popover.Button key={index} as={Link} href={item.href} className={classNames(pathname.includes(item.href) ? "border-violet-500 bg-violet-900 text-yellow-bds" : "border-transparent text-white hover:border-gray-300 hover:bg-gray-50 hover:text-violet-300", "block border-l-4 py-2 pl-3 pr-4 text-base font-medium")}>
            {item.name}
          </Popover.Button>)}
      </div>
      <div className="border-t border-gray-200 pb-3 pt-4">
        <div className="flex items-center px-4">
          {user && <>
              <div className="flex-shrink-0">
                <Avatar className="h-10 w-10 rounded-full" src={undefined} alt={user.email} />
              </div>
              <div className="ml-3">
                <p className="text-base font-medium text-yellow-bds">
                  {user.last_name} {user.first_name}
                </p>
                <p className="text-sm font-medium text-white">{user.email}</p>
              </div>
            </>}
          {/* <button
           type="button"
           className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
           >
           <span className="sr-only">View notifications</span>
           <HeartIcon className="h-6 w-6" aria-hidden="true" />
           </button> */}
        </div>
        <div className="mt-3 space-y-1">
          {user ? userNavigation.map(item => <Popover.Button key={item.name} as="button" className="block w-full px-4 py-2 text-left text-base font-medium text-white hover:bg-gray-100 hover:text-violet-300" onClick={item.onClick}>
                {item.name}
              </Popover.Button>) : <>
              <Popover.Button as="button" className="block w-full px-4 py-2 text-left text-base font-medium text-white hover:bg-gray-100 hover:text-violet-300" onClick={() => router.push(NavigateRouter.LOGIN)}>
                {t("header.Sign in")}
              </Popover.Button>
              <Popover.Button as="button" className="block w-full px-4 py-2 text-left text-base font-medium text-white hover:bg-gray-100 hover:text-violet-300" onClick={() => router.push(NavigateRouter.REGISTER)}>
                {t("header.Sign up")}
              </Popover.Button>
            </>}
        </div>
      </div>
    </Popover.Panel>;
});
export default HeaderSP;